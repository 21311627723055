<!--
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-15 15:57:13
 * @LastEditTime: 2021-01-15 18:01:56
 * @FilePath: \acuconference-web\src\views\Admin.vue
-->
<template>
  <div class="admin-wrapper">
    <router-view />
  </div>
</template>

<script>
export default {
  
}
</script>

<style lang="sass" scoped>
.admin-wrapper
  position: relative
  width: 100%
  height: 100%
</style>